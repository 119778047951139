export default function DeathToBoring() {
  return (
    <section className="grid padding-gutter section-5 gap-12 border-b pb-24 pt-24 mb-12 bg-black">
      <p className="text-[2rem] leading-none font-black font-display text-white">
        Death to boring ideas! Thinknado will annihilate them with the fury of a
        thousand angry parrots.
      </p>
      <div className="flex flex-col gap-6">
        <h2 className="text-[3rem] font-black text-white">Wait Why?</h2>
        <p className="text-lg text-white">
          Wait…why are we stuck with formulaic problem solving?
        </p>
        <p className="text-lg text-white">
          Wait…why is it so hard to break the status quo?
        </p>
        <p className="text-lg text-white">
          Wait…why do we exercise our bodies but not our brains?
        </p>
      </div>
      <div className="flex flex-col gap-6">
        <h2 className="text-[3rem] font-black text-white">Maybe?</h2>
        <p className="text-lg text-white">
          Maybe it’s possible to help people reject boring ideas and imagine
          creative possibilities that were previously invisible?
        </p>
      </div>
      <div className="flex flex-col gap-6">
        <h2 className="text-[3rem] font-black text-white">What If?</h2>
        <p className="text-lg text-white">
          What if this tool was disguised as a game? What if this tool would
          make you an expert at “wrong” thinking?What if this tool was fun to
          play for all ages?
        </p>
      </div>
      <div className="flex flex-col gap-6">
        <h2 className="text-[3rem] font-black text-white">Try This.</h2>
        <p className="text-lg text-white">
          Try this. It’s called Thinknado... like tornado. Imagine a twister of
          creativity in your skull. What could you possibly lose, except boring
          ideas.
        </p>
      </div>
    </section>
  );
}
