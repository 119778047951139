export const isServer = typeof window === "undefined";

export const isBrowser = typeof window !== "undefined";

export const wait = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const generateID = () => {
  const randomString = Math.random().toString(36).substring(16);
  return randomString;
};

export const isPromise = (p: any) => {
  if (typeof p === "object" && typeof p.then === "function") {
    return true;
  }

  return false;
};
